.wrapperClass {
  width: 100%;
  height: calc(100vh - 64px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  position: relative;
}
.wrapperClass::after {
  content: "";
  width: 100%;
  height: calc(100vh - 200px);
  background-image: url("../../assets/Images/touchpointSVG.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9;
}
.wraperclass-product:after {
  content: "";
  width: 100%;
  height: calc(100vh - 300px);

  right: 0;
  bottom: 0;
  z-index: 9;
  background-image: url("../../assets/Images/ProductBG.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.wrapperClass h1 {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
  color: #303030;
  line-height: 60px;
}
.wrapperClass h1 span {
  color: #f57e75;
}
.textbox {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
  position: relative;
}
.textbox a {
  color: #285f90;
  transition: 0.5s;
}
.textbox a:hover {
  color: #f57e75;
}
.textbox h2 {
  color: #053742;
}

.edit-touchpoint-form .ant-form-item-label label {
  font-size: 16px;
}
