.integration-modal {
  display: "flex";
  justify-content: "space-between";
}
.ant-collapse-item {
  background: white !important;
  border-color: 2px solid #d3d3d3 !important;
}

.shopify-accordians-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: transparent;
}

.shopify-accordians-container .ant-collapse-item {
  border: none;
}

.shopify-accordians-container .ant-collapse-header {
  padding: 19px 15px !important;
  background: #f5f5f5;
  border-radius: 7px !important;
}

.accordian-header-container {
  display: flex;
  gap: 10px;
}

.accordian-title,
.shopify-accordians-container .instruction-text,
.shopify-accordians-container .extension-title-text,
.shopify-accordians-container .native-sms-title {
  font-family: "Hellix";
  font-size: 16px;
  line-height: 19px;
  color: #252525;
}

.accordian-title .dark-text {
  font-weight: 700;
}

.shopify-accordians-container .ant-collapse-content-box {
  padding: 20px 15px 0px !important;
}

.shopify-accordians-container .instruction-text {
  font-weight: 600;
}

.shopify-accordians-container .concent-form-label {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #252525;
}

.concent-text-form-save-container,
.install-checkout-extension-container,
.native-sms-button-container {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 30px;
}

.install-checkout-extension-container,
.native-sms-button-container {
  margin-bottom: 10px;
}

.concent-text-form-save-container .concent-modal-link,
.install-checkout-extension-container .checkout-install-modal-link,
.native-sms-button-container .native-sms-modal-link {
  font-family: "Hellix";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #f37064;
  margin-bottom: 0px;
  cursor: pointer;
}

.shopify-accordians-container .extension-title-text,
.shopify-accordians-container .native-sms-title {
  margin-bottom: 8px;
  font-weight: 600;
}

.shopify-accordians-container .extension-text,
.shopify-accordians-container .native-sms-text {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #252525;
}

.disconnect-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 12px 20px 12px 12px;
  background: #fff2f2;
  border-radius: 8px;
  margin-bottom: 43px;
}

.disconnect-box .disconnect-content-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.disconnect-text-container .disconnect-title {
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #e24b4c;
  margin-bottom: 5px;
}

.disconnect-text-container .disconnect-text {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #252525;
  margin-bottom: 0px;
}

.disconnect-box .disconnect-button {
  border: 1px solid #e24b4c;
  color: #e24b4c;
  background: transparent;
}

.textalign {
  margin-left: 10px !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.shopifyText {
  margin-left: 15px !important;
  font-size: 18px !important;
  font-weight: bold !important;
}
.icon-css {
  color: #686868 !important;
  font-size: 18px !important;
}
.ant-collapse-content-box {
  padding-left: 0px !important;
}
.title-css {
  font-size: 16px !important;
  margin-left: 4px !important;
  color: #686868 !important;
}
.switch-css.ant-switch-checked {
  background-color: #124173 !important;
}
.tritech-form .ant-form-item-label {
  line-height: 1px !important;
}
.input-text {
  height: 42px !important;
  font-size: 18px !important;
}

.contact-model .ant-modal-content {
  width: 400px !important;
  margin: auto;
}
.contact-model-text .ant-modal-body {
  line-height: 1 !important;
}

.contact-model .ant-modal-content {
  width: 437px !important;
}
.yesno-btn {
  border-radius: 100px !important;
  height: auto;
  padding: 10px 30px;
  font-weight: 700;
}

.platform-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 20vh;
  min-height: 170px;
  background-color: #eff4f9;
  border-radius: 7px;
  cursor: pointer;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: transparent;
}

.ant-carousel .slick-prev::before,
.ant-carousel .slick-next::before,
.ant-carousel .slick-prev::before:hover,
.ant-carousel .slick-next::before:hover {
  font-size: inherit;
  color: black !important;
}

.shopify-icon {
  height: 67px;
  width: 59px;
}

.klaviyo-icon {
  height: 38px;
  width: 57px;
  margin-top: 20px;
}

.tritech-icon {
  height: 64px;
  width: 64px;
}

.prestashop-icon,
.bigcommerce-icon {
  height: 62px;
  width: 62px;
}

.bigcommerce-icon {
  border-radius: 50%;
}

.plateform-name {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.shopify-integration-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-title-container {
  display: flex;
  align-items: center;
  padding: 22px 0px 22px 22px;
  gap: 22px;
}

.back-arrow {
  cursor: pointer;
}

.platform-page-title-container {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #232426;
}

.shopify-header-buttons-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 11px 16px 10px 0px;
}

.update-concent {
  margin: 0px;
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #3b84d1;
  cursor: pointer;
}

.connect-shopify,
.connect-shopify:visited {
  padding: 12px 24px;
  height: 42px;
  border: 1px solid #f37064;
  color: white;
  background-color: #f37064;
  align-self: flex-start;
  border-radius: 20px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 700;
}
.connect-shopify:hover {
  color: white;
}

.platform-title-divider {
  border: 1px solid #d9eafb;
  margin: 0px;
}

.integration-shopify-content {
  margin-top: 20px;
}

.klaviyo-integration-key {
  padding: 10px 12px;
  width: 100%;
  height: 42px;
  border: 0.5px solid #959595;
  border-radius: 6px;
}

.klaviyo-integration-key::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.klaviyo-activate-button {
  padding: 12px 24px;
  width: 129px;
  height: 42px;
  border: 1px solid #f37064;
  border-radius: 22px;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #f37064;
  align-self: flex-start;
}

.klaviyo-attribute-mapping-header-container {
  margin: 18px 0px 0px 0px !important;
  padding: 0px !important;
}

.klaviyo-attribute-mapping-header {
  display: flex;
  justify-content: space-between;
  padding: 24px 30px !important;
  border: 2px solid #d9eafb;
  border-radius: 6px;
}

.klaviyo-header-logo-text-container {
  display: flex;
  gap: 25px;
  align-items: center;
}

.klaviyo-header-text-container {
  display: flex;
  flex-direction: column;
}

.klaviyo-header-title-text {
  font-family: "Hellix";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #252525;
}

.klaviyo-header-subtitle-text {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
}

.klaviyo-header-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 14px;
}

.klaviyo-header-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  width: 119px;
  height: 39px;
  background-color: #252525;
  border-radius: 22px;
  color: white;
}

.reload-icon-text-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.reload-icon {
  color: #868686;
}

.last-updated-text {
  margin-bottom: 0px !important;
  color: #868686;
  font-style: italic;
}

.attribute-tab-title {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

#klaviyo-panel .ant-tabs-nav {
  border-bottom: 2px solid #d9eafb;
}

#klaviyo-panel .ant-tabs-tab {
  padding-top: 20px;
}

.klaviyo-integration-container,
.blustream-integration-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 14px;
}

.klaviyo-integration-logo-wrapper,
.blustream-integration-logo-wrapper {
  height: 42px;
  width: 47px;
  background-color: #eff4f9;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.klaviyo-attribute-title-text,
.blustream-attribute-title-text {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #232426;
}

.klaviyo-contact-attriubute-container,
.blustream-contact-attriubute-container {
  position: relative;
  height: 270px;
  width: 285px;
  padding: 20px;
  border: 1px solid #d9eafb;
  border-radius: 6px;
  overflow: scroll;
}

.klaviyo-attribute-text,
.blustream-attribute-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #959595;
  margin: 18px auto 16px 19px;
}

.klaviyo-title-focus,
.blustream-title-focus {
  color: #124173;
}

.klaviyo-attribute-container,
.blustream-attribute-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.klaviyo-attribute-name,
.blustream-attribute-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
}

.already-mapped-attribute {
  width: 13px;
  height: 13px;
  margin-left: 6px;
}

.integration-klaviyo-map-button,
.integration-klaviyo-mapped-products-button,
.integration-klaviyo-finish-button {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  border-radius: 22px;
  height: 46px;
}

.integration-klaviyo-map-button {
  width: 124px;
  background: #3b84d1;
  color: white;
}

.integration-klaviyo-mapped-products-button {
  width: 237px;
  border: 1px solid #3b84d1;
  color: #3b84d1;
}

.integration-klaviyo-finish-button {
  width: 95px;
}

.direction-text {
  font-family: "Hellix";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #959595;
  margin-bottom: 0px !important;
}

.contact-attribute-mapping-container .main-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
}

.contact-attribute-mapping-container .mapped-attributes-table-wrapper {
  display: flex;
  justify-content: space-between;
}

.contact-attribute-mapping-container .custom-table-column-header {
  display: flex;
  align-items: center;
  color: #124173;
  height: 44px;
  font-weight: 600;
  font-size: 16px;
  line-height: 134.19%;
  padding-left: 15px;
  background: #eff4f9;
  border-radius: 6px 6px 0px 0px;
}

.contact-attribute-mapping-container .blustream-column-header,
.contact-attribute-mapping-container .klaviyo-column-header {
  width: 322px;
}

.contact-attribute-mapping-container .arrow-column-header {
  width: 25px;
  height: 44px;
  visibility: none;
}

.contact-attribute-mapping-container .action-column {
  margin-left: 13px;
}

.contact-attribute-mapping-container .action-column-header {
  width: 151px;
}

.contact-attribute-mapping-container .custom-table-row {
  height: 48px;
}

.contact-attribute-mapping-container .blustream-row,
.contact-attribute-mapping-container .klaviyo-row {
  display: flex;
  align-items: center;
  width: 322px;
  padding-left: 15px;
  border: 1px solid #e8f1fb;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
}

.contact-attribute-mapping-container .arrow-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.contact-attribute-mapping-container .half-right-arrow,
.contact-attribute-mapping-container .half-left-arrow {
  width: 20px;
  height: 10px;
}

.contact-attribute-mapping-container .action-row {
  display: flex;
}

.contact-attribute-mapping-container .edit-action-wrapper,
.contact-attribute-mapping-container .delete-action-wrapper {
  width: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8f1fb;
}

.contact-attribute-mapping-container .edit-icon,
.contact-attribute-mapping-container .delete-icon {
  height: 15px;
  width: 15px;
}

.contact-attribute-mapping-container .add-new-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  width: 146px;
  height: 46px;
  color: #3b84d1;
  border: 1.5px solid #3b84d1;
  border-radius: 22px;
  background-color: transparent;
}

.mapping-modal .ant-modal-header {
  display: flex;
  justify-content: center;
  background: #eff4f9;
  padding: 20px !important;
  border-radius: 8px 8px 0px 0px;
  text-transform: capitalize;
}

.mapping-modal .ant-modal-title {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
}

.mapping-modal .ant-modal-close {
  height: 64px;
}

.mapping-modal .ant-modal-body {
  padding: 20px 30px !important;
}

.mapping-modal .mapping-modal-content {
  display: flex;
  gap: 15px;
}

.mapping-modal .flow-arrow {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #3b84d1;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.mapping-modal .klaviyo-col {
  display: flex;
  flex-direction: column;
}

.mapping-modal .indication-text {
  margin-top: 32px;
  font-family: "Hellix";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #959595;
}

.mapping-modal .integration-klaviyo-map-button {
  margin-top: 20px;
  align-self: flex-end;
}

.success-modal-container .success-icon {
  height: 26px;
  width: 26px;
}

.success-modal-container .success-text {
  margin: 0px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #25b291;
}

.success-modal-container {
  display: flex;
  gap: 20px;
  margin-top: 23px;
}

.success-modal-container .showing-success-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  margin-bottom: 20px;
}

.success-modal-for-attributes .success-modal {
  padding: 10px 20px !important;
}

.success-modal-for-attributes .success-modal-dark-text {
  max-width: 345px !important;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #252525;
}

.success-modal-for-attributes {
  width: 437px !important;
}

.edit-attribute-mapping-modal .ant-modal-header {
  padding: 20px 30px !important;
  justify-content: space-between;
}

.edit-attribute-mapping-modal .edit-modal-header-wrapper {
  display: flex;
  justify-content: space-between;
}

.edit-attribute-mapping-modal .ant-modal-title {
  width: calc(100% - 20px);
}

.edit-modal-header-wrapper .blustream-logo {
  height: 13px;
  width: 13px;
  margin-right: 4px;
}
.edit-modal-header-wrapper .klaviyo-logo {
  height: 11px;
  width: 16px;
  margin-right: 4px;
}

.edit-modal-header-wrapper .chain-icon {
  margin: 0px 10px;
}

.edit-modal-header-wrapper .blustream-attribute,
.edit-modal-header-wrapper .klaviyo-attribute {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #252525;
}

.edit-modal-header-wrapper .attribute-mapping {
  display: flex;
  align-items: center;
}

.edit-attribute-mapping-modal .save-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 84px;
  height: 43px;
  background-color: #f37064;
  border-radius: 22px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 20px;
  align-self: flex-end;
}

.delete-attribute-modal .warning-modal-dark-text {
  min-width: 373px;
}

.shopify-slider .slick-list {
  margin-left: 7px;
}

.shopify-steper-main-container {
  background-color: #eff4f9;
  background-image: url("./../../assets/Images/topLeftBackground.svg"),
    url("./../../assets/Images/bottomRightBackground.svg");
  background-position: left top, bottom right;
  background-repeat: no-repeat;
  position: relative;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shopify-steper-main-container .colorful-blustream-logo-container {
  display: flex;
  justify-content: center;
  padding: 42px 0px !important;
  position: relative;
}

.colorful-blustream-logo {
  padding: 5px 0px;
}

.stepper-logout {
  position: absolute;
  right: 0px;
}

.shopify-steper-main-container .progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 44px;
}

.shopify-steper-main-container
  .progress-bar-container
  .progress-bar-container2 {
  display: flex;
  flex-direction: column;
}

.progress-bar-container2 .progress-step-text2 {
  align-self: center;
}

.shopify-steper-main-container .progress-step-text1,
.shopify-steper-main-container .progress-step-text2 {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #252525;
  margin: 0px;
}

.shopify-steper-main-container .ant-progress {
  width: 328px;
  margin-bottom: 38px;
}

.shopify-steper-main-container .ant-progress-bg {
  height: 6px !important;
}

.shopify-steper-main-container .unboxing-text {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #252525;
  max-width: 646px;
}

.shopify-steper-main-container .unboxing-text-2 {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #252525;
  max-width: 510px;
  margin-bottom: 12px;
}

.shopify-steper-main-container .account-text {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #686868;
  margin-bottom: 44px;
}

.shopify-steper-main-container .shopify-search-container {
  display: flex;
  gap: 10px;
  margin-bottom: 31px;
}

.shopify-search-container .shopify-search {
  width: 570px;
}

.shopify-search-container .shopify-search .ant-input-affix-wrapper {
  height: 42px;
  background-color: #f5f5f5;
  border: none;
}

.shopify-search-container .shopify-search .ant-input {
  background-color: #f5f5f5;
}

.shopify-steper-main-container .product-type-title {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
  padding: 8px 0px 7px 24px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
}

.steper-1 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 31px;
}

.unboxing-step-2 .shopify-product {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 42px;
}

.unboxing-step-2 .product-image {
  height: 56px;
  width: 56px;
}

.unboxing-step-2 .product-name {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #252525;
  margin: 0px;
}

.explore-own-section {
  display: flex;
  flex-direction: column;
}

.explore-own-section .first-text {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #252525;
  margin-bottom: 11px;
}

.explore-own-section .second-text {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #686868;
  margin-bottom: 14px;
}
.explore-own-section .email-container {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 32px;
}

.explore-own-section .email {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #252525;
  margin-bottom: 0px;
}
.explore-own-section .edit-icon {
  height: 12px;
  width: 12px;
}
.explore-own-section .explore-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 187px;
  height: 43px;
  background: #3b84d1;
  border-radius: 100px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.welcome-modal {
  width: 464px;
}

.welcome-modal .ant-modal-content {
  border-radius: 28px !important;
}

.welcome-modal .ant-modal-body {
  padding: 28px;
}

.welcome-modal .welcome-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 17px;
}

.welcome-modal .welcome-title {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  color: #252525;
}

.welcome-modal .welcome-text {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #252525;
  max-width: 365px;
}

.welcome-modal .continue-button,
.steper-1 .continue-button,
.steper-2 .continue-button,
.welcome-to-blustream-modal .continue-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 115px;
  height: 43px;
  background: #f37064;
  border-radius: 100px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.steper-2 {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
}

.steper-2 .continue-button {
  background-color: #bababa !important;
}

.unboxing-step-2 .second-text-2 {
  max-width: 523px !important;
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #686868;
}

.cell-phone-input-container {
  display: flex;
  align-items: center;
  gap: 19px;
}

.cell-phone-input-container .ant-input-group-compact {
  width: 270px;
}

.contact-sales-section .input-label {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 9px !important;
}

.cell-phone-input-container .first-input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  width: 47px !important;
  height: 44px;
}

.cell-phone-input-container .second-input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 44px;
  width: 220px;
}

.cell-phone-input-container .submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 40px;
  width: 133px;
  height: 45px;
  background: #f37064;
  border-radius: 30px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.explore-own-section .button-container {
  display: flex;
  gap: 12px;
}

.explore-own-section .rainbow-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 294px;
  height: 43px;
  border: 2px solid #3b84d1;
  border-radius: 100px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #3b84d1;
}

.contact-sales-page .contact-sales-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  justify-content: center;
  background-color: white;
  margin-bottom: 41px;
  border-radius: 28px;
  padding: 210px 330px 280px;
}

.contact-sales-page .contact-sales-image-section {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-sales-page .first-text {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #252525;
  margin-bottom: 0px;
}

.contact-sales-page .second-text,
.contact-sales-page .third-text {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #686868;
  margin-bottom: 0px;
}

.contact-sales-page .rainbow-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 40px;
  width: 366px;
  height: 45px;
  background: #f37064;
  border-radius: 30px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
}

.shopify-user-form .phone-number .ant-input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.shopify-user-form .email .ant-form-item-label label {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
}

.shopify-user-form .email .ant-input {
  height: 44px;
}

.shopify-user-form {
  margin-bottom: 40px;
}

.contact-sales-section .contact-text {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #252525;
  max-width: 275px;
}

.contact-sales-section .submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 188px;
  height: 43px;
  background: #f37064;
  border-radius: 100px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.thank-you-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  padding: 40px;
}

.thank-you-modal-content .thank-you-image {
  width: 183px;
  height: 179px;
}

.thank-you-modal-content .thank-you-text {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #252525;
  min-width: 432px;
}

.thank-you-modal-content .thank-you-text-2 {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #686868;
  min-width: 432px;
}

.thank-you-modal-content .highlighted-text {
  color: #252525;
}

.thank-you-modal-content .continue-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 24px;
  width: 212px;
  height: 45px;
  background: #f37064;
  border-radius: 30px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
}

.concent-text-modal {
  width: auto !important;
  max-width: 864px !important;
  max-height: 646px !important;
}

.concent-text-modal .ant-modal-body {
  padding: 40px !important;
}

.concent-text-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.concent-text-modal-content .concent-text-title {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}

.concent-text-modal-content .concent-text-image {
  width: 385px;
  height: 494px;
}

.concent-text-modal-content .second-column {
  display: flex;
  flex-direction: column;
}

.concent-text-modal-content .tcpa-text {
  max-width: 356px;
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 7px;
}

.concent-text-modal-content .tcpa-link,
.concent-text-modal-content .tcpa-link:focus,
.concent-text-modal-content .tcpa-link:visited,
.integration-tcpa-link,
.integration-tcpa-link:focus,
.integration-tcpa-link:visited {
  display: block;
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-decoration: underline !important;
  color: #f37064;
  margin-bottom: 38px;
}

.concent-text-modal-content .notice-text {
  min-width: 370px;
  font-family: "Hellix";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #686868;
  margin-bottom: 15px;
}

.concent-text-modal-content .concent-text-area {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  width: 370px;
  height: 170px;
  border: 0.5px solid #959595;
  border-radius: 6px;
  margin-bottom: 21px;
}

.concent-text-modal-content .concent-text-area::placeholder {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
}

.concent-title-area {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  width: 370px;
  height: 44px;
  border: 0.5px solid #959595;
  border-radius: 6px;
  margin-bottom: 10px;
}

.concent-text-modal-content .concent-title-area::placeholder {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
}

.concent-text-modal-content .save-button-disabled .ant-btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 84px;
  height: 43px;
  background: #f37064;
  border-radius: 100px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.concent-text-modal-content .save-button-enabled {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 84px;
  height: 43px;
  background: #f37064;
  border-radius: 100px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.concent-text-modal-content .next-button-disabled .ant-btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 13px 24px;
  width: 82px;
  height: 43px;
  background: #dfdfdf;
  border-radius: 30px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.concent-text-modal-content .buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.concent-text-modal-content .next-button-enabled {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 13px 24px;
  width: 82px;
  height: 43px;
  background: #bababa;
  border-radius: 30px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.checkout-extension-modal {
  width: min-content !important;
}

.checkout-extension-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.checkout-extension-modal-content .checkout-extension-title {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #000000;
}

.checkout-extension-modal-content .extension-gif {
  max-width: 788px !important;
  max-height: 469px !important;
  border: 2px solid #ededed;
}

.checkout-extension-modal-content .prev-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 24px;
  width: 80px;
  height: 43px;
  background: #bababa;
  border-radius: 30px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.checkout-extension-modal-content .shopify-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 223px;
  height: 43px;
  background: #f37064;
  border-radius: 100px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.checkout-extension-modal .ant-modal-body {
  padding: 30px 38px 20px !important;
}

.shopify-loader .ant-spin-dot-item {
  background-color: #124173 !important;
}

.integration-tcpa-text {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  max-width: 785px;
  margin-top: 25px;
  margin-bottom: 4px;
}

.integration-tcpa-link,
.integration-tcpa-link:focus,
.integration-tcpa-link:visited {
  margin-bottom: 20px;
  text-decoration: underline !important;
}

.integration-concent-text-title {
  margin: 20px 0px;
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}

.integration-concent-instruction-text {
  font-family: "Hellix";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #686868;
  margin: 21px 0px 3px;
}

.integration-concent-text-area {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #252525;
  min-height: 145px !important;
  border: 0.5px solid #959595;
  border-radius: 6px;
  margin-bottom: 14px;
}

.integration-concent-title-area {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #252525;
  min-height: 44px !important;
  border: 0.5px solid #959595;
  border-radius: 6px;
  margin-bottom: 14px;
}

.integration-concent-save-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 84px;
  height: 43px;
  background: #f37064;
  border-radius: 100px;
  font-family: "Hellix";
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: 20px 0px;
}

.integration-concent-save-button:hover,
.integration-concent-save-button:focus,
.integration-go-to-shopify-button:hover,
.integration-go-to-shopify-button:focus {
  color: white;
  background: #f37064;
  border-color: #f37064;
}

.shopify-concent-image2 {
  width: 788px;
  height: 469px;
  border: 2px solid #ededed;
  border-radius: 6px;
}

.integration-go-to-shopify-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: fit-content;
  height: 43px;
  background: #f37064;
  border-radius: 100px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.integration-go-to-shopify-button:hover {
  color: white;
}

.integration-disconnect-app-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: fit-content;
  height: 43px;
  border: 1.5px solid #959595;
  border-radius: 100px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: white !important;
  background: #959595 !important;
}

.integration-disconnect-app-button:hover,
.integration-disconnect-app-button:focus {
  color: white;
  background: #959595;
  border-color: #959595;
}

.no-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.no-product-text {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #959595;
}

.connected-store-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 794px;
  height: 703px;
  background-color: #fbfbfb;
}

.connected-store-container .description-text {
  max-width: 570px;
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #252525;
  margin-top: 30px;
  margin-bottom: 0px;
}

.connected-store-container .store-name {
  font-family: "Hellix";
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  color: #3b84d1;
  margin-top: 14px;
  margin-bottom: 20px;
}

.connected-store-container .first-sub-text {
  max-width: 327px;
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #252525;
  margin-top: 20px;
}

.connected-store-container .second-sub-text {
  max-width: 395px;
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: start;
  color: #959595;
  margin-left: 30px;
  margin-bottom: 26px;
}

.connected-store-container .second-sub-text > p {
  margin-bottom: 5px;
}

.connected-store-container .shopify-store-url {
  /* max-width: 174px; */
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  color: #252525;
  margin-top: 14px;
}

.tritech-back-arrow {
  position: absolute;
  top: 22px;
  left: 22px;
  cursor: pointer;
}

.tritech-platform-page-title-container {
  display: flex;
  justify-content: flex-start;
  gap: 22px;
  align-items: center;
  height: 64px;
  font-family: "Hellix";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #232426;
  margin-left: 22px;
}

.switch-delete-container {
  display: flex;
  align-items: center;
  gap: 18px;
}

.tritech-delete {
  height: 18px;
  cursor: pointer;
}

.tritech-verify-message-container,
.store-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 12px;
}

.tritech-message-icon,
.store-detail-container .success-icon {
  width: 17px;
  height: 16px;
}

.tritech-verified-message,
.tritech-not-verified-message,
.store-detail-container .store-text-message {
  margin-bottom: 0px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.store-detail-container {
  padding: 12px;
  margin-bottom: 30px;
}

.tritech-verified-message,
.store-text-message {
  color: #25b291;
}

.tritech-not-verified-message {
  color: #ff5a5a;
}

.tritech-delete-modal,
.tritech-delete-modal-button-wrapper {
  display: flex;
  gap: 17px;
  margin-top: 23px;
}

.tritech-delete-modal .delete-content-container {
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-tritech-confirm-button,
.disable-tritech-confirm-button,
.enable-tritech-confirm-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  width: fit-content;
  height: 40px;
  background: #f37064;
  border-radius: 8px;
  font-family: "Hellix";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.delete-tritech-confirm-button:hover,
.delete-tritech-confirm-button:focus,
.disable-tritech-confirm-button:hover,
.disable-tritech-confirm-button:focus,
.enable-tritech-confirm-button:hover,
.enable-tritech-confirm-button:focus {
  color: white;
  background: #f37064;
  border-color: #f37064;
}

.tritech-save-button,
.tritech-test-button,
.delete-tritech-denied-button,
.disable-tritech-denied-button,
.enable-tritech-denied-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  width: fit-content;
  height: 40px;
  gap: 10px;
  border: 1px solid #f37064;
  border-radius: 8px;
  font-family: "Hellix";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f37064;
}

.tritech-save-button:hover,
.tritech-save-button:focus,
.tritech-test-button:hover,
.tritech-test-button:focus,
.delete-tritech-denied-button:hover,
.delete-tritech-denied-button:focus,
.disable-tritech-denied-button:hover,
.disable-tritech-denied-button:focus,
.enable-tritech-denied-button:hover,
.enable-tritech-denied-button:focus {
  color: #f37064;
  border-color: #f37064;
}

.contact-model .buttons-container {
  display: flex;
  gap: 17px;
  margin-top: 22px;
  margin-bottom: 6px;
}

.tcpa-section {
  display: flex;
  gap: 10px;
  padding: 12px 20px 12px 12px;
  background: #eff4f9;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tcpa-section .tcpa-text-section {
  display: flex;
  gap: 10px;
  align-items: center;
}

.tcpa-section .tcpa-text {
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 0px;
}

.shopify-native-concent-modal .ant-modal-body,
.shopify-activate-extension-modal .ant-modal-body {
  background: #e9e9e9;
  border-radius: 0px !important;
  padding: 20px 20px 0px;
}

.shopify-native-concent-modal .ant-modal-footer,
.shopify-activate-extension-modal .ant-modal-footer {
  background: #e9e9e9;
  border: none !important;
  padding: 18px 16px 18px;
  border-radius: 0px;
}

.shopify-native-concent-modal .ant-modal-footer > div,
.shopify-activate-extension-modal .ant-modal-footer > div {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.shopify-native-concent-modal .slider-button,
.shopify-native-concent-modal .slider-button:hover,
.shopify-activate-extension-modal .slider-button,
.shopify-activate-extension-modal .slider-button:hover,
.whatsapp-slider-controls .slider-button,
.whatsapp-slider-controls .slider-button:hover {
  background: #dcdcdc;
  border-radius: 50%;
  font-family: "Hellix";
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #252525;
  padding: 3px 12px;
  border-color: #dcdcdc;
}

.shopify-native-concent-modal .current-slide,
.shopify-native-concent-modal .current-slide:hover,
.shopify-activate-extension-modal .current-slide,
.shopify-activate-extension-modal .current-slide:hover,
.whatsapp-slider-controls .current-slide,
.whatsapp-slider-controls .current-slide:hover {
  border-color: #dcdcdc;
  color: #40a9ff;
}

.shopify-native-concent-modal .ant-modal-close-icon,
.shopify-activate-extension-modal .ant-modal-close-icon,
.shopify-concent-text-modal .ant-modal-close-icon {
  position: absolute;
  left: 70px;
  font-size: 22px;
  color: white;
}

.shopify-native-concent-modal img,
.shopify-activate-extension-modal img,
.shopify-concent-text-modal img {
  width: 100%;
}

.shopify-concent-text-modal img {
  border-radius: 7px;
}

.shopify-concent-text-modal .ant-modal-body {
  padding: 0px;
}

.shopify-disconnect-main-Row {
  margin: 48px 0px 85px;
}

.shopify-disconnect-main-Row .shopify-disconnect-main-Col {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.shopify-disconnect-main-Col .shopify-disconnect-image {
  width: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.shopify-disconnect-sub-Col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shopify-disconnect-step-title,
.tritech-step-title {
  font-family: Hellix;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #232426;
  margin-bottom: 12px;
}

.shopify-disconnect-step-text,
.tritech-step-text {
  font-family: Hellix;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #232426;
}

.tritech-step-text {
  margin-bottom: 0px;
}

.tritech-note-text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #959595;
  margin-bottom: 124px;
}

.copy-container {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 12px;
  background-color: #eff4f9;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3b84d1;
  margin-bottom: 0px !important;
}

.copy-icon {
  padding-left: 7px;
  z-index: 10;
}

.endpoint-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.endpoint-title {
  line-height: 1;
  margin-bottom: 0px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.secret-key-main-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.secret-key-container {
  display: flex;
}

.reveal-secret-key-button {
  position: absolute;
  z-index: 10;
  margin-top: 1.5px;
}

.blur-box {
  filter: blur(3px);
}

.secret-key-main-container .generate-new-secret-key {
  width: fit-content;
}

.shopify-integration-copyright-text {
  color: #959595;
  text-align: center;
  font-family: Hellix;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 25px;
}
.tritech-event-main-row {
  margin-top: 25px;
}

.tritech-event-main-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f9fbfd;
  border-bottom: 1px solid #608fc2;
  padding: 26px 66px;
  margin-bottom: 10px;
}

.tritech-event-main-col .edit-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.tritech-event-main-col .action-title,
.tritech-event-main-col .description-title,
.tritech-event-main-col .transaction-status-title {
  color: #124173;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid #d9eafb;
  background: #eff4f9;
  padding: 8px;
}

.tritech-event-main-col .action-value {
  color: #252525;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tritech-event-main-col .description-value,
.tritech-event-main-col .transaction-status-value {
  color: #252525;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tritech-event-main-col .transaction-status-values-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tritech-event-configure-modal-content-wrapper {
  padding: 20px 20px 40px;
}

.tritech-event-configure-modal-content-wrapper .field-title {
  color: #252525;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.transaction-status-container {
  display: flex;
  gap: 20px;
}

.transaction-status-container .remove-field-button {
  margin-top: 12px;
}

.remove-field-button {
  font-size: 18px;
  color: #959595;
}

.tritech-event-configuration-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tritech-event-configuration-form .ant-input {
  width: 350px;
}

.tritech-event-configure-modal-content-wrapper .important-text {
  color: #3b84d1;
  font-family: Hellix;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 20px 0px;
}

.tritech-event-configure-modal-content-wrapper .add-status-button {
  width: 100% !important;
}

/* ----------------------------------- whatsapp ------------------------------------------- */

.whatsapp-success-message-container {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.whatsapp-guidence-text-container {
  display: flex;
  gap: 10px;
  padding: 16px 12px;
  align-items: center;
  border-radius: 8px;
  background: #eff4f9;
  margin-bottom: 20px;
}

.whatsapp-guidence-text {
  color: #252525;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.whatsapp-slider-controls {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.whatsapp-slide {
  display: flex !important;
  justify-content: center;
}

.whatsapp-slide-text-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
  min-height: 88px;
}

.whatsapp-slide-text-title {
  color: #232426;
  font-family: Hellix;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.whatsapp-slide-text {
  color: #232426;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.whatsapp-step-title {
  color: #232426;
  font-family: Hellix;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 12px;
}

.whatsapp-step-guidence-text {
  color: #252525;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 20px;
}

.whatsapp-step-guidence-text .highlighted-text {
  font-weight: 700 !important;
}

.whatsapp-integration-form .ant-form-item-label {
  padding-bottom: 10px;
}

.whatsapp-integration-form .ant-form-item-label label {
  color: #252525;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.whatsapp-verified-button-container .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.whatsapp-verified-button-container
  .ant-form-item-control-input-content
  .verified-icon {
  height: 25px;
  width: 25px;
}

.whatsapp-integration-form .ant-input-password input {
  padding: 0px !important;
}

.whatsapp-step-divider {
  border: 0.5px solid #d9eafb;
  margin: 0px;
  margin-bottom: 24px;
}

.whatsapp-copiable-content-wrapper {
  display: flex;
  align-items: center;
  /* gap: 10px; */
  margin-bottom: 20px;
}

.whatsapp-copiable-content-title {
  color: #252525;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.whatsapp-disable-box {
  padding: 12px;
  border-radius: 4px;
  background: #ececec;
  color: #868686;
  font-family: Hellix;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 40px;
}

.whatsapp-step2-verified-button-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.verified-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.whatsapp-step2-verified-button-container .verified-icon {
  height: 25px;
  width: 25px;
}

/* .copy-container {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  white-space: break-spaces;
  display: inline-block;
} */

.whatsapp-callback-container {
  white-space: break-spaces;
}

/* ----------------------------------- media queries ------------------------------------------- */

@media only screen and (max-width: 992px) {
  .shopify-native-concent-modal .ant-modal-footer > div,
  .shopify-activate-extension-modal .ant-modal-footer > div {
    gap: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .shopify-native-concent-modal .ant-modal-footer > div,
  .shopify-activate-extension-modal .ant-modal-footer > div {
    gap: 10px;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 1600px) {
  .shopify-disconnect-step-title {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 20px;
  }
  .shopify-disconnect-step-text {
    font-size: 20px;
    line-height: 26px;
  }
}

@media only screen and (min-width: 2000px) {
  .shopify-disconnect-step-title {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .shopify-disconnect-step-text {
    font-size: 24px;
    line-height: 30px;
  }
}
